.tooltip {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.tooltip-content {
    position: absolute;
    left: 0;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 0;
    transform: translateY(calc(-100% - 10px));
    z-index: 999999;   
}

.tooltip-pointer {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #1e293b;
}

.tooltip-text {
    position: relative;
    white-space: nowrap;
    border-radius: 0.375rem;
    background-color: #4b5563;
    padding: 0.625rem;
    font-size: 14px;
    line-height: 1.625;
    letter-spacing: 0.025em;
    color: white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    font-weight: 400;
}